declare var gtag

class Analytics {
  triggerEvent(eventName, evtParams) {
    // console.log(`Google Analytics Event: ${eventName}`)
    // console.log(`Params: ${JSON.stringify(evtParams)}`)
		gtag('event', eventName, evtParams)
		// console.log("Gtag called", gtag)
  }
}

export default new Analytics()