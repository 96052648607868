import * as React from 'react'
import { Component } from 'react'
import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import './ThreeGradient.css'

const TWEEN = require('@tweenjs/tween.js')
const win:any = window;
const minTime = 2000;
const maxTime = 1000;

interface ThreeGradientProps {
  showImages?:boolean
  children: React.ReactNode
}

class ThreeGradient extends Component<ThreeGradientProps, {}> {

	private threeCanvasRef = React.createRef<HTMLDivElement>()
  private renderer:any
  private scene:any
  private fbxLoader:any
  private camera:any
  private lightPurple:any
  private lightYellow:any
  private mouseTarget:any

	componentDidMount() {

    this.animate = this.animate.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.animateToPositionPurple = this.animateToPositionPurple.bind(this)
    this.animateToPositionYellow = this.animateToPositionYellow.bind(this)

    this.mouseTarget = {x:0, y:0}

    this.scene = new THREE.Scene()
    this.fbxLoader = new FBXLoader()

    let element = this.threeCanvasRef.current!
    let aspect = element.clientWidth / element.clientHeight

    this.camera = new THREE.PerspectiveCamera( 45, element.clientWidth / element.clientHeight, 0.1, 1000 )

    win.onmousemove = this.onMouseMove

    this.renderer = new THREE.WebGLRenderer()
    this.renderer.setSize( element.clientWidth, element.clientHeight )
    element.appendChild( this.renderer.domElement )

    let lightMult = 2.5

    this.lightPurple = new THREE.PointLight( 0x511635, 7 * lightMult, 1.6 )
    this.lightPurple.position.set( 1, 0.2, 1 )
    this.lightPurple.layers.set(0)
    this.scene.add( this.lightPurple )
    
    this.lightYellow = new THREE.PointLight( 0x2c2516, 10 * lightMult, 1.6 )
    this.lightYellow.position.set( -1, 0, 1 )
    this.lightYellow.layers.set(0)
    this.scene.add( this.lightYellow )

    this.camera.position.x = 0
    this.camera.position.y = 0
    this.camera.position.z = 1

    window.onresize = this.onResize

    this.fbxLoader.load(
      '/fbx/plane.fbx',
      (object) => {
        object.material = new THREE.MeshLambertMaterial( { color: 0xffffff } )
        object.position.set(0, 0, 0)
        object.scale.set(100,100,1)
        object.layers.set(0)
        this.scene.add( object )
      },
      (xhr) => {
      },
      (error) => {
          console.log(error)
      }
    )

    this.animate();
	}

  onMouseMove(evt) {
    let mouseX = -(0.5 - (evt.clientX / evt.view.innerWidth)) 
    let mouseY = (0.5 - (evt.clientY / evt.view.innerHeight)) * 1.5
    this.mouseTarget = {x: mouseX, y: mouseY}
  }

  onResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize( window.innerWidth, window.innerHeight )
  }

  animateToPositionPurple(target:any) {
    let props = {x: target.position.x, y: target.position.y}
    new TWEEN.Tween(props)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .to({x: 1+ ((Math.random() * 0.4) - 0.2), y: Math.random()}, minTime + (Math.random() * maxTime))
      .to({x: 1+ ((Math.random() * 0.4) - 0.2), y: -Math.random()}, minTime + (Math.random() * maxTime))
      .onUpdate(() => {
        target.position.set(props.x, props.y, 1)
      })
      .onComplete(() => {
        this.animateToPositionPurple(target)
      })
      .start()
  }

  animateToPositionYellow(target:any) {
    let props = {x: target.position.x, y: target.position.y}
    new TWEEN.Tween(props)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .to({x: -1 + ((Math.random() * 0.4) - 0.2), y: 1}, minTime + (Math.random() * maxTime))
      .to({x: -1 + ((Math.random() * 0.4) - 0.2), y: -1}, minTime + (Math.random() * maxTime))
      .onUpdate(() => {
        target.position.set(props.x, props.y, 1)
      })
      .onComplete(() => {
        this.animateToPositionYellow(target)
      })
      .start()
  }

  animate() {
    requestAnimationFrame( this.animate )

    TWEEN.update()

    this.lightPurple.position.set(1, this.lerp(this.lightPurple.position.y, this.mouseTarget.y, 0.02), 1)
    this.lightYellow.position.set(-1, -this.lerp(this.lightPurple.position.y, this.mouseTarget.y, 0.02), 1)
    this.renderer.render(this.scene, this.camera)
  }

	render () {
		return (
      <div className='three-gradient'>
        <div className='renderer-container' ref={this.threeCanvasRef}></div>
        {
          this.props.showImages && this.props.showImages === true ? (
            <div className='overlay-container'>
              <img className='wifi' src='/img/transparent-wifi.png' />
              <img className='sim' src='/img/transparent-Sim.png' />
            </div>
          ) : null
        }
        <div className='child-container'>
          { this.props.children }
        </div>
      </div>
		);
	}

  lerp (start, end, amt){
    return (1-amt)*start+amt*end
  }
}

export default ThreeGradient