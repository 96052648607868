import '../../styles/typography.less'
import '../../styles/controls.less'
import './index.less'
import * as React from "react"
import { Component } from "react"
import StaticHeader from '../shared/Header/StaticHeader'
import ThreeGradient from '../shared/ThreeGradient/ThreeGradient'
import Analytics from '../../utility/Analytics'
import { createRoot } from 'react-dom/client'

class Application extends Component<{}, {}> {

	private submitForm = React.createRef<HTMLFormElement>()

	componentDidMount() {

		Analytics.triggerEvent('Page Load', {'screen_name': 'Login'})

		this.doSubmitForm = this.doSubmitForm.bind(this);
	}

	doSubmitForm(){
		if(!this.submitForm.current) return;
		this.submitForm.current.submit();
	}

	render () {
		return (
			<ThreeGradient showImages={true}>
				<div className="application-frame">
					<StaticHeader />
					<div className="login-frame">
						<form action="/login" method="post" ref={this.submitForm}>
							<h2>Login to your online<br></br>SimUI account</h2>
							<input type="text" placeholder="Email Address" name="username" autoFocus={true} tabIndex={1}/>
							<input type="password" placeholder="Password" name="password" autoComplete='current-password' tabIndex={2}/>
							<a href='/reset'>Reset password</a>
							<div className="button gradient" onClick={() => this.doSubmitForm()} onKeyDown={(evt) => {
								if (evt.keyCode === 13) {
									this.doSubmitForm()
								}
							}} tabIndex={3}>
								Login
							</div>
						</form>
					</div>
				</div> 
			</ThreeGradient>
		);
	}
	
}

const root = createRoot(document.getElementById("app"))
root.render(
	<Application/>
)