import './SiteHeader.less';
import * as React from "react";
import { Component } from "react";

interface SiteHeaderState {
}

interface SiteHeaderProps {
}

export default class SiteHeader extends Component<SiteHeaderProps, SiteHeaderState> {

	constructor(props:any, state:any) {
		super(props, state);
	}

	componentDidMount() {
		
	}

	render () {
		return (
			<header className="site-header" style={{backgroundColor:'transparent'}}>
				<div className='top-bar'>
					<div className='left panel'>
						<a href="/"><img className="logo" src="/img/SimUi-Colour.png"></img></a>
					</div>
				</div>
			</header>
		)
	}
}